
import React, { useEffect } from "react";
import { MARGIN_MEDIUM } from "../Shared/layout-constants";
import { useDispatch } from "react-redux";
import { getAdminDashboardData } from "../../actions/adminActions";
import AiResultGraph from "../Admin/AIResultGraph";

const AdminDashboard: React.FC = (props: any) => { 

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAdminDashboardData({}))
      }, []);

    return <div style={{margin: MARGIN_MEDIUM ,textAlign: 'left', marginTop: MARGIN_MEDIUM}}>
        <AiResultGraph />
    </div>

}
export default AdminDashboard

import ThumbDown from "@mui/icons-material/ThumbDown";
import ThumbUp from "@mui/icons-material/ThumbUp";
import { Card, CardContent, Icon, Tooltip, Typography } from "@mui/material/";
import * as React from "react";
import { Discuss } from "react-loader-spinner";
import { REACT_APP_SERVER_DOMAIN } from "../../constants";
import { COLORS, MARGIN_MEDIUM } from "../../constants/layout-enums";

interface IAIResponseBlockProps {
  downloadPath: string;
  aiResult: any;
  aiFetching: boolean;
  aiResultId: string;
  aiText: string;
  updateAIFeedback: any;
  feedback: string;
  feedbackAttributeName: string;
  setParentState: any;
  preSearchPrompt: string;
  aiQuotedNameString: string;
}

const AIResponseBlock: React.FC<any> = (props: IAIResponseBlockProps) => {

  const renderSourceNodes = (source_nodes: any) => {
    const uniqueNodes = [] as any[];
    source_nodes.forEach((node: any) => {
      const existing = uniqueNodes.find((x) => x?.source_url === node?.source_url);
      if (!existing) {
        uniqueNodes.push(node);
      }
    });
    return (
      uniqueNodes.map((node: any, index: number) => {
        return (
          <p key={index} style={{ cursor: "pointer" }} onClick={() => {
            const stateObj = {
              documentView: true,
              document: REACT_APP_SERVER_DOMAIN + props.downloadPath + node?.original_file_name?.replace(/%25/g, "%2525"),
            }
            props.setParentState(stateObj);
          }}>
            <span style={{
              color: "blue",
              textDecoration: "underline"
            }}>{node?.friendly_title}</span>
          </p>
        );
      }));
  };

  return (
    <div>
      <Card style={{ minWidth: 375, margin: MARGIN_MEDIUM, backgroundColor: COLORS.WHITE_PAPER }}>
        <CardContent>
          {(!props.aiText && !props.aiFetching) && <>
            <Icon><img style={{ width: 24, color: "#A2E4B8" }} className="icon" src={`/images/lightbulb.svg`} /></Icon>
            {props.preSearchPrompt}

          </>}
          {props.aiText && <>

            <Typography variant="body2">
              <b>{props.aiQuotedNameString}</b>

            </Typography>

            <Typography variant="body2" style={{ textAlign: 'left' }}>


              <br />

              {props.aiText.includes("I don't know") ? <span style={{ textAlign: 'left' }}>
                MSHA Copilot’s body of knowledge is limited to MSHA statute, regulations, guidance documents and case law.  The AI is having trouble generating a response to this question.  We’ve logged this query to evaluate relevance and continue to improve the AI training.
              </span> : <span style={{ textAlign: 'left' }}>{props.aiText}</span>}
              <br /><br />

            </Typography>
            {props.aiResult?.source_nodes?.length > 0 && !props.aiText.includes("I don't know") ? renderSourceNodes(props.aiResult?.source_nodes) : null}

            {props.aiResultId != null && <>
              <span style={{
                marginRight: 8,
                cursor: "pointer",
                color: props.feedback === "thumbs_down" ? "red" : "#000000",
              }} onClick={() => {
                props.setParentState({
                  [props.feedbackAttributeName]: "thumbs_down"
                });
                props.updateAIFeedback({
                  payload: {
                    id: props.aiResultId,
                    user_feedback: "THUMBS_DOWN",
                  }
                });
              }}><ThumbDown /></span>

              Was the AI helpful?
              <span style={{
                marginLeft: 8,
                cursor: "pointer",
                color: props.feedback === "thumbs_up" ? "green" : "#000000",
              }} onClick={() => {
                props.setParentState({
                  [props.feedbackAttributeName]: "thumbs_up"
                });
                props.updateAIFeedback({
                  payload: {
                    id: props.aiResultId,
                    user_feedback: "THUMBS_UP",
                  }
                });
              }}><ThumbUp /></span>

              <div style={{ marginTop: 8 }}><i style={{ fontSize: 12 }}>* Please note AI is experimental and may not be accurate. The information provided is not legal advice. </i></div>
            </>}

          </>}
          {props.aiFetching && <div><Discuss
            visible={true}
            height="40"
            width="40"
            colors={["#D3D3D3", "#D3D3D3"]}
            ariaLabel="comment-loading"
          // wrapperStyle={{color: 'black', backgroundColor: 'black'}}
          /></div>}

        </CardContent>
        {/* <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions> */}
      </Card>
    </div>
  );
};

export default AIResponseBlock;

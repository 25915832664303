import { isString } from "lodash";
import {jwtDecode} from 'jwt-decode';

interface JwtPayload {
  exp?: number; // Expiration time
}

export function isJwtExpired(token: string, forceLogout = true): boolean {
  try {
    // Decode the JWT token
    const decoded: JwtPayload = jwtDecode<JwtPayload>(token); // Use generic to type the decoded object

    if (!decoded || !decoded.exp) {
      if (forceLogout) {
        localStorage.clear();
        window.location.href = "/";
      }
      return true; // Consider token expired if no exp field
    }

    // Get current time in seconds
    const currentTime = Math.floor(Date.now() / 1000);

    // Check if the token is expired
    return decoded.exp < currentTime;
  } catch (error) {
    console.error("Error decoding token:", error);
    if (forceLogout) {
      localStorage.clear();
      window.location.href = "/";
    }
    return true; // Consider invalid or malformed tokens as expired
  }
}

export function GetFriendlyError(error: any): string {
  try {
    if (error?.response?.data?.message) {
      return error.response.data.message;
    }
    if (isString(error)) {
      return error as string;
    }
  } catch (e) {
    console.log(e);
  }
  return "Error: Unknown Error";
}

export const safeBool = (
  input: string | boolean,
  defaultValue = false,
): boolean => {
  try {
    let newInput = input;
    if (typeof input === "string") {
      newInput = input.toLowerCase();
    }
    if (newInput === "true" || newInput === true) {
      return true;
    }
    if (newInput === "false" || newInput === false) {
      return false;
    }
    return defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

export const boolToString = (val: any, trueVal: string, falseVal: string) => {
  let boolval = false;
  if (typeof val === "boolean") {
    boolval = val;
  } else {
    if (typeof val === "string") {
      if (val.toLowerCase() === "true") {
        boolval = true;
      }
    }
  }
  if (boolval === true) {
    return trueVal;
  }
  return falseVal;
};

export const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const getUrlParam = (param: string) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(param);
};

export const roundNumberFixed2 = (num: any): number => {
	try {
		return Math.round((Number(num) + Number.EPSILON) * 100) / 100;
	}
	catch {
		return 0
	}
}


import { Button, TextField } from "@mui/material/";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUserInfo, updateBasicUserInfo, updateUserInfo } from "../../actions/userActions";
import { REACT_APP_WP_DOMAIN } from "../../constants";
import { IRootState } from "../../interfaces/rootState";
import Checkboxes from "../Checkboxes/Checkboxes";
import "./UserEdit.css";
import { UserEditField } from "./UserEditField";

export interface Props {
  getUser: any;
  updateUser: any;
  updateBasicUser: any;
  mainState: any;
}

export interface State {
  userBasic: any;
  userAdvanced: any;
  miningMethod: any;
  commodity: any;
}

const UserEdit: React.FC = (props: any) => {

  const loggedUser = useSelector((state: IRootState) => state.mainState.loggedUser);
  const [userBasic, setUserBasic] = useState(loggedUser);
  const [userAdvanced, setUserAdvanced] = useState(null as any);
  const [miningMethod, setMiningMethod] = useState([]);
  const [commodity, setCommodity] = useState([]);

  const mainState = useSelector((state: IRootState) => state.mainState);

  const dispatch = useDispatch();

  const save = (values: any) => {
    // tslint:disable-next-line
    let payload = {
      data: [
        {
          key: "Title/Role",
          value: values.Role
        },
        {
          key: "Company/Group",
          value: values.Company
        },
      ]
    };
    commodity.forEach((comm: any) => {
      payload.data.push({ key: "Mining Commodities", value: comm });
    });
    miningMethod.forEach((meth: any) => {
      payload.data.push({ key: "Mining Methods", value: meth });
    });

    const basicUserPayload = {
      display_name: values?.Name,
      user_email: values.Email,
    };

    dispatch(updateUserInfo(payload, () => {
      dispatch(updateBasicUserInfo(basicUserPayload) as any);
    }) as any);
  };

  useEffect(() => {
    if (!window.localStorage.jwt || window.localStorage.jwt === "undefined") {
      window.localStorage.clear();
    } else {
      dispatch(getUserInfo({}, (data: any) => {
        setUserAdvanced(data);
      }) as any);
    }
  }, []);

  let company = "Company";
  let role = "Role";

  if (userAdvanced?.results?.length) {
    company = userAdvanced.results.find((x: any) => x.key === "Company/Group").value;
    role = userAdvanced.results.find((x: any) => x.key === "Title/Role").value;
  }

  return (
    <div className="UserEditContainer">
      <div className="spacing" />
      <Grid container spacing={1}>
        <Grid item sm={6}>
          <Formik
            enableReinitialize
            initialValues={{
              Name: userBasic.name,
              Email: userBasic.email,
              Role: role,
              Company: company,
            }}
            onSubmit={(values) => {
              save(values);
            }}
          >
            {({ values }) => (
              <Form >
                <h2>Profile</h2>
                <span> Name</span>

                <div className="userFields">
                  <Field disabled={false} name="Name" label="Name" component={UserEditField} />
                </div>
                <span> Email</span>

                <div className="userFields">
                  <Field disabled={false} name="Email" label="Email" component={UserEditField} />
                </div>
                <span>Company/Group</span>

                <div className="userFields">
                  <Field disabled={false} name="Company" label="Company/Group" component={UserEditField} />
                </div>
                <span>Title/Role</span>
                <div className="userFields">
                  <Field disabled={false} name="Role" label="Title/Role" component={UserEditField} />
                </div>
                <Checkboxes
                  updateMining={(value: any) => setMiningMethod(value)}
                  updateCommodity={(value: any) => setCommodity(value)}
                  userAdvanced={userAdvanced}
                />
                <Button className="signinButton" style={{ marginTop: "20px !important" }} type="submit">Save Changes</Button>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid container sm={6}>
          <Grid item sm={12}>
            <h2>Billing</h2>
            <div className="rightUserFields">
              <TextField disabled variant="outlined" label="User ID" name="name" value={userBasic.id} />
            </div>
            <div className="rightUserFields">
              <TextField disabled variant="outlined" label="Account Role" name="Account Role" value="Administrator" />
            </div>
          </Grid>
          {/* <Grid item sm={6} style={{textAlign: "left", margin: "auto"}}>
                    <span>Billing Plan </span>
                    <div className="billingPlan">
                        <a>Single User</a>
                        <div>$18/month</div>
                    </div>
                </Grid> */}
          <Button className="accountButton" href={REACT_APP_WP_DOMAIN + "/my-account/"}>Manage Subscription</Button>
          <div className="userInfoContainer">
            <Typography className="userFirstText">Happy Searching!</Typography>
            <br />
            <Typography className="userSecondText"> Please Contact us at:</Typography>
            <Typography className="userSecondText"> Phone: (202) 262-4081</Typography>
            <Typography className="userSecondText"> Email: mark@mshawise.com</Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserEdit;

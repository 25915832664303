import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import HttpClient from '../../api/HttpClient'; // Adjust the path
import LineGraph from '../Graph/LineGraph';

const client = new HttpClient();

const AiResultGraph: React.FC = () => {
  // Calculate default date range
  const today = dayjs();
  const startOfCurrentMonth = today.startOf('month');
  const startOfLastMonth = today.subtract(1, 'month').startOf('month');
  const defaultStartDate =
    today.date() > 15 ? startOfCurrentMonth : startOfLastMonth;
  const defaultEndDate = today;

  // State variables
  const [startDate, setStartDate] = useState<Dayjs | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Dayjs | null>(defaultEndDate);
  const [chartData, setChartData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [showGraph, setShowGraph] = useState(false);

  const fetchAiResults = async () => {
    if (!startDate || !endDate) return;

    setLoading(true);
    try {
      const url = 'ai/count-by-date-range';
      const params = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };

      const response = await client.get(url, { params });

      if (response.data) {
        const results = response.data;

        // Prepare chart data
        const labels = results.map((item: any) => item.createdAt);
        const dataset = results.map((item: any) => parseInt(item.count, 10));

        setChartData({
          labels,
          datasets: [
            {
              label: 'AI Results Over Time',
              data: dataset,
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 2,
              tension: 0.4,
            },
          ],
        });
        setShowGraph(true);
      }
    } catch (error) {
      console.error('Error fetching AI results:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (setter: React.Dispatch<React.SetStateAction<Dayjs | null>>, newValue: Dayjs | null) => {
    setter(newValue);
    setShowGraph(false); // Hide graph until "Search" is clicked
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Searches Run Graph
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box display="flex" gap={2} marginBottom={3}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => handleDateChange(setStartDate, newValue)}
            slotProps={{ textField: { fullWidth: false } }}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => handleDateChange(setEndDate, newValue)}
            slotProps={{ textField: { fullWidth: false } }}
          />
        </Box>
      </LocalizationProvider>
      <Box marginBottom={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={fetchAiResults}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Search'}
        </Button>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : showGraph && chartData ? (
        <LineGraph
          chartData={chartData}
          uuid="ai-results-graph"
          height={400}
          width={600}
        />
      ) : (
        !loading && <Typography>No data available</Typography>
      )}
    </div>
  );
};

export default AiResultGraph;

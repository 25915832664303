import axios from "axios";
import { all, call, put } from "redux-saga/effects"; // call
import { actionIds } from "../../actions/actionIds";
import HttpClient, { objectToQuery } from "../../api/HttpClient";
import { HttpResponse } from "../../interfaces/HttpResponse";
import {GetFriendlyError, isJwtExpired} from "../../utils/general";
import { autoYields } from "../schema";
const client = new HttpClient();
const API_URL = process.env.REACT_APP_SERVER_DOMAIN || "https://endpointnotfound";
export async function genericGet(data: any, url: string): Promise<HttpResponse<{ x: string }>> {
  return client.getV2(url, data);
}



export async function genericPost(data: any, url: string): Promise<HttpResponse<{ x: string }>> {
  //console.log("url", API_URL + "/" + url);
  //console.log("data in genericPost?", data);

  // Retrieve the JWT token from localStorage
  const token = window.localStorage.getItem('jwt');

  if (token){
    isJwtExpired(token || '');
  }

  // Construct the headers with the Authorization token
  const headers = token ? { Authorization: `Bearer ${token}` } : {};

  // if (data.payload) {
  //   console.log("Payload detected, including Authorization header");
  // }

  return await axios.post(API_URL + "/" + url, data, { headers });

}


export async function genericPut(data: any, url: string): Promise<HttpResponse<{ x: string }>> {
  return client.put(url, data);
}

export async function genericDelete(data: any, url: string): Promise<HttpResponse<{ x: string }>> {
  return client.delete(`${url}/${data.id}`);
}

export const genericSaga = {

  *showError(action: any): Generator {
    try {
      console.log("error action", action);
      yield put({ type: actionIds.ERROR, payload: {error: action.payload, epoch: new Date().getTime()} });

    } catch (e) {
      console.log(e);
    }
  },

  *genericPassthrough(action: any, passthroughActionName = ""): Generator {
    if (passthroughActionName !== "") {
      yield put({ type: passthroughActionName, payload: action.payload });
    }
    return action
  },

  *genericHandler(action: any, apiEndpoint: any, doAutoYields = true, passthroughActionName = ""): Generator {
    try {
      if (action.autoHandleLoading === true) {
        yield put({ type: actionIds.LOADING });
      }
      const response = (yield call(apiEndpoint, action.payload)) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (doAutoYields) {
          const yields = autoYields(response.data);
          if (yields.length > 0) {
            yield all(yields);
          }
        }
        if (passthroughActionName !== "") {
          yield put({ type: passthroughActionName, payload: response.data });
        }
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
        if (action.autoSuccessMessage) {
          yield put({ type: actionIds.SUCCESS, payload: {message: action.autoSuccessMessage, epoch: new Date().getTime()} });
        }
        if (action.autoHandleLoading === true) {
          yield put({ type: actionIds.NOT_LOADING });
        }
      } else {
        throw response.status;
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
      if (action.autoHandleLoading === true) {
        yield put({ type: actionIds.NOT_LOADING });
      }
      if (action.autoHandleError === true) {
        const error = e as Error;
        const friendlyError = GetFriendlyError(error);
        yield put({ type: actionIds.ERROR, payload: {error: friendlyError, epoch: new Date().getTime()} });
      }
    }
  },
};
